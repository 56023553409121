import axios from 'axios';
import store from '../store'

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
});

axiosInstance.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        handleError(error)
    }
);

const handleError = ({ response }) => {
    store.commit('SET_ERROR_STATUS', true) // just taking some guesses here
    store.commit('SET_ERROR_MESSAGE', "API Fehler: " + response.status + " " + response.data.error) // just taking some guesses here
}

const getTableData = (id) => {
    return axiosInstance.get('/data/' + id);
}
const getUsers = () => {
    return axiosInstance.get('/user');
}

const createUser = (user) => {
    return axiosInstance.post('/user', user);
}
const createCustomColumn = (customColumn) => {
    return axiosInstance.post('/custom_columns', {
        ...customColumn
    });
}
const createHighlightRule = (rule) => {
    return axiosInstance.post('/highlight_rules', {
        ...rule
    });
}

const deleteUser = (api_key) => {
    return axiosInstance.delete('/user', {
        data: {
            api_key
        }
    });
}
const deleteCustomColumn = (customColumn) => {
    return axiosInstance.delete('/custom_columns', {
        data: {
            ...customColumn
        }
    });
}
const deleteHighlightRule = (rule) => {
    return axiosInstance.delete('/highlight_rules', {
        data: {
            ...rule
        }
    });
}

const updateTableData = (id) => {
    return axiosInstance.put('/data/' + id);
}
const updateCustomColumn = (customColumn) => {
    return axiosInstance.patch('/custom_columns', {
        ...customColumn
    });
}
const updateCustomData = (customData) => {
    return axiosInstance.patch('/custom_data', {
        ...customData
    });
}
const updateRedmineColumn = (redmineColumn) => {
    return axiosInstance.patch('/redmine_columns', {
        ...redmineColumn
    });
}
const updateRedmineData = (redmineData) => {
    return axiosInstance.patch('/redmine_data', {
        ...redmineData
    });
}
const updateHighlightRule = (rule) => {
    return axiosInstance.patch('/highlight_rules', {
            ...rule
    });
}

export {
    getTableData,
    getUsers,

    createUser,
    createCustomColumn,
    createHighlightRule,

    deleteUser,
    deleteCustomColumn,
    deleteHighlightRule,

    updateTableData,
    updateCustomColumn,
    updateCustomData,
    updateRedmineColumn,
    updateRedmineData,
    updateHighlightRule
}
